import React from 'react'
import Logo from './Logo'
import "./Home.css"
function Home() {
  return (
    <div className="app-container">
      <div className='logo-container'>
      <Logo/>

      </div>

    </div>
  )
}

export default Home