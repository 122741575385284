// eslint-disable-next-line
import React, { useEffect } from 'react';  
import translations from './constants/translations'
import {useDispatch} from 'react-redux';
import Layout from './components/app';
import "./App.css"
// import checkOut from './components/checkout'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IntlProvider } from 'react-redux-multilingual'
import "./bootstrap.css"
import {trans} from "./reducers/translate/actions";
// import   AccountingContainerv1 from './containers/Accounting/indexv1';
// import   AccountingContainerv2 from './containers/Accounting/indexv2';
import Home from './components/NewDesign/Home/Home';
import JoinTeam from './components/JoinTeam';
import "external-svg-loader";
function App() {
    const dispatch = useDispatch()

    useEffect(() => {
   dispatch({type:"GET_DATAI_ADV"})
   dispatch({type:"GET_COUNTRIES_SAGA"})
        if(localStorage.getItem("lang") &&localStorage.getItem("lang") && localStorage.getItem("lang").length>0 ){
            dispatch(trans(localStorage.getItem("lang")))
        }
        else{
            let lang =window.navigator.language || window.navigator.languages[0] || window.navigator.user || window.navigator.browserLanguage || window.navigator.userLanguage
            if(lang.slice(0,2) ==="ar" || lang.slice(0,2)==="en" || lang.slice(0,2) ==="tr")
            dispatch(trans(lang.slice(0,2)))
            else{
                dispatch(trans("en"))
            }
        }

        
    },[])
      return (
          
               <IntlProvider translations={translations} locale='en'>
				<BrowserRouter basename={'/'} >
                     <Layout>
                     <Routes>
                     <Route path={`/joinTeam`} element={<JoinTeam/>}/>
                                <Route exact path={`/`} element={<Home/>}/>
                     </Routes>
                      
                                {/* <Route exact path={`/Accounting`} component={AccountingContainerv1}/>
                                <Route exact path={`/Accountingv1`} component={AccountingContainerv1}/>
                                <Route exact path={`/Accountingv2`} component={AccountingContainerv2}/> */}

                            </Layout>
					</BrowserRouter> 
                </IntlProvider>
           
        )
    
}

export default App;
